<template>
  <div class="content">
    <div>
      <OpenAppHead :options="options" @getshowimg="getshowimg" />
    </div>
    <div class="Return">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/17/yuelvhuikOaxEr3QhQ1608889995.png"
        alt=""
        @click="goReturn"
      >
    </div>
    <div class="TicketContent">
      <div class="TicketType">
        <div class="TicketOneWay" :class="isType === 0 ? 'bgcImage1' : 'bgcImage2'" @click="TicketOneWay">单程</div>
        <div class="TicketReturn" :class="isType === 1 ? 'bgcImage3' : 'bgcImage4'" @click="TicketReturn">往返</div>
      </div>
      <div class="TicketInfo">
        <div class="VoteAddress">
          <div class="FromText" @click="changeAddress(0)">{{ goFrom }}</div>
          <div class="RoundTripImg" @click="ExchangeAddress">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/19/yuelvhuiCLlOcE52Ni1608897378.png"
              alt=""
            >
          </div>
          <div class="ArrivalText" @click="changeAddress(1)">{{ goArrival }}</div>
        </div>
        <div class="van-hairline--bottom" />
        <div class="IntervalTimeWrap" @click="isShowCalendar">
          <div class="DepartureTime">
            <p>{{ startTime }}<span>{{ startTimeDate }}</span></p>
          </div>
          <div v-if="isType === 1" class="RoundTripInterval">
            <div class="RoundTripImg">
              <p>{{ formatDateDaysText }}</p>
            </div>
          </div>
          <div v-if="isType === 1" class="returnTime">
            <p>{{ endTime }}<span>{{ endTimeDate }}</span></p>
          </div>
        </div>
        <div class="van-hairline--bottom" />
        <div class="isChildren">
          <img :src="isShowChildren ? isChildrenImg1 : isChildrenImg" alt="" @click="isChildren">
          <p>携带儿童<span>2-12岁</span></p>
        </div>
        <div v-if="isShowChildren" class="ChildrenReservations" @click="ShowChildrenReservations">
          <p>儿童预定说明</p>
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/12/yuelvhuiW5e6yPE8bN1609042033.png"
            alt=""
          >
        </div>
        <div class="SearchStyle" @click="SearchTicket">搜索</div>
        <div v-if="isShowSearchHistory" class="SearchHistoryWrap">
          <div class="SearchHistory">
            <div
              v-for="(item,index) in SearchHistoryList"
              :key="index"
              class="SearchContent"
              @click="SearchProcess(item)"
            >
              <p>{{ item.startText }}-{{ item.endText }}</p>
              <div class="TravelTime">
                <p v-if="item.isOneWay">{{ item.DepartureTime }}</p>
                <div v-else>
                  <p>{{ item.startTime }}去</p>
                  <p>{{ item.endTime }}返</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ClearHistory" @click="ClearHistory">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/12/yuelvhuiA4Q7UM5Mh21609042759.png"
              alt=""
            >
            <p>清空历史</p>
          </div>
        </div>
      </div>
    </div>
    <!--       儿童预定说明 -->
    <van-action-sheet v-model="isShowChildrenNote" title="儿童预定说明">
      <div class="ChildrenNoteWrap">
        <div class="ChildrenNote">
          <div v-for="(item,index) in ChildrenNote" :key="index" class="noteContentWrap">
            <div class="noteContent">
              <div><p>{{ item.name }}</p></div>
              <div><p v-html="item.note" /></div>
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <!--        单程日历选择-->
    <van-calendar v-model="OneWayShow" title="选择出发日期" @confirm="onConfirm" />
    <!--        往返日历选择-->
    <van-calendar v-model="show" title="选择往返日期" type="range" :formatter="formatter" @confirm="onConfirms" />
    <!--        选择出发往返地址-->
    <van-action-sheet v-model="isAddress" :title="AddressText">
      <van-index-bar :index-list="indexList">
        <!--                搜索框-->
        <div class="SearchBoxWrap">
          <van-search v-model="SearchValue" :left-icon="leftIcon" placeholder="搜索城市中英文或拼音" @input="onSearch" />
        </div>
        <!--                搜索列表-->
        <div v-if="isSearchList" class="SearchListWrap">
          <p v-if="SearchCityList.length === 0" class="NoSearchData">抱歉，没有相关城市</p>
          <div v-else class="SearchData">
            <div v-for="(item,index) in SearchCityList" :key="index" @click="selSearchCity(item,1)">
              <p>{{ item.airport_name }}</p>
              <div class="van-hairline--bottom" />
            </div>
          </div>
        </div>
        <!--                热门城市-->
        <div v-else>
          <p class="hostCityStyle">热门城市</p>
          <div class="AddressList">
            <p
              v-for="(hostItem,index) in hostCity"
              :key="index"
              class="AddressListCell"
              :class="selAddressCode === hostItem.threeCode ? 'AddressListCell2' : ''"
              @click="selSearchCity(hostItem,0)"
            >{{ hostItem.city }}</p>
          </div>
          <!--               所有城市 -->
          <div v-for="(item) in cityList" :key="item.header">
            <van-index-anchor :index="item.header">{{ item.header }}</van-index-anchor>
            <div class="AddressList">
              <p
                v-for="(items) in item.children"
                :key="items.threeCode"
                class="AddressListCell"
                :class="selAddressCode === items.threeCode ? 'AddressListCell2' : ''"
                @click="selSearchCity(items,0)"
              >{{ items.city }}</p>
              <!--                        <van-cell class="AddressListCell" :class="selAddressCode === items.threeCode ? 'AddressListCell2' : ''" v-for="(items,index) in item.children" :title="items.city" :key="items.threeCode" />-->
            </div>
          </div>
        </div>
      </van-index-bar>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet, Calendar, Cell, IndexAnchor, IndexBar, Search
} from 'vant'

Vue.use(Calendar)
  .use(ActionSheet)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Cell)
  .use(Search)

import {
  getCityList,
  searchCityList
} from '@/services/comeOn.js'
import OpenAppHead from '../../../component/GoodsDetail/openApp'

export default {
  name: 'Index',
  components: {
    OpenAppHead
  },
  data() {
    return {
      isType: 0, // 0单程1往返
      fromText: '北京', // 出发地
      ArrivalText: '上海', // 到达地
      goFrom: '',
      goArrival: '',
      isExchangeAddress: true, // 控制交互地址
      OneWayShow: false, // 单程日历显示
      show: false, // 往返日历显示
      startTime: '', // 出发时间
      startTimeDate: '', // 出发周天
      endTime: '', // 返回时间
      endTimeDate: '', // 返回周天
      formatDateDaysText: '', // 往返间隔天数
      isShowChildren: false, // 是否携带儿童
      isChildrenImg: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/11/yuelvhuiIURs8C87Xd1609041216.png',
      isChildrenImg1: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/11/yuelvhuiQKjM3vSQdQ1609041587.png',
      isShowChildrenNote: false, // 显示儿童预定说明
      ChildrenNote: [
        {
          name: '年龄规定',
          note: '2周岁（含）- 12周岁（不含）'
        },
        {
          name: '票价',
          note: '承认普通票价的50%' + '<br>' + '免机场建设费，燃气费'
        },
        {
          name: '证件',
          note: '身份证，护照，户口簿'
        },
        {
          name: '注意事项',
          note: '每位成人旅客(满18周岁)最多携带2名儿童' + '<br>' + '儿童旅客必须有同舱位成人(满18周岁)陪伴乘机，无成人陪伴儿童请提前联系航空公司进行预定'
        }
      ], // 儿童预定说明
      SearchHistoryList: [], // 搜索历史
      isShowSearchHistory: true, // 是否显示搜索历史
      isAddress: false, // 是否显示地址索引栏
      AddressText: '选择出发地', // 选择地址title
      indexList: [], // 地址索引栏选项
      cityList: [], // 城市列表
      hostCity: [], // 热门城市列表
      selAddressCode: '', // 选中城市的code
      SearchValue: '', // 搜索输入的城市
      isSearchList: false, // 是否显示搜索城市列表
      SearchCityList: [], // 搜索城市列表
      leftIcon: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/16/yuelvhuihUwNCWfaom1609056378.png',
      goFromCode: 'BJS', // 选中出发城市码
      goFromCode1: 'BJS',
      goArrivalCode: 'SHA', // 选中目的地城市码
      goArrivalCode1: 'SHA',
      startTimes: '', // 年月日的选中开始时间
      endTimes: ''// 年月日的选中返程时间
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }
      const info = {
        product_type: 46
      }
      obj.info = info
      return obj
    }
  },
  created() {

  },
  mounted() {
    this.goFrom = this.fromText
    this.goArrival = this.ArrivalText
    this.startTime = (new Date()).getMonth() + 1 + '月' + (new Date()).getDate() + '日'// 默认出发时间是今天
    this.startTimeDate = '今天'// 默认是今天
    this.startTimes = this.formatDates(new Date())
    this.getCityLists()// 城市列表
    this.SearchHistoryVote()// 搜索历史记录
  },
  methods: {
    // 打开APP
    getshowimg(item) {
      this.showimg = item
    },
    // 返回上一页
    goReturn() {
      this.$router.go(-1)
    },
    // 单程
    TicketOneWay() {
      this.isType = 0
    },
    // 往返
    TicketReturn() {
      this.isType = 1
      this.startTime = (new Date()).getMonth() + 1 + '月' + (new Date()).getDate() + '日'// 默认出发时间是今天
      this.startTimeDate = '今天'
      this.startTimes = this.formatDates(new Date())
      const day3 = new Date()
      day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000)// 明天的毫秒值
      const s3 = (day3.getMonth() + 1) + '月' + day3.getDate() + '日'
      this.endTime = s3// 默认返回地址为明天
      this.endTimeDate = '明天'
      let month, day
      if ((new Date(day3)).getMonth() + 1 < 10) {
        month = '0' + ((new Date(day3)).getMonth() + 1)
      } else {
        month = new Date(day3).getMonth() + 1
      }
      if ((new Date(day3)).getDate() < 10) {
        day = '0' + (new Date(day3)).getDate()
      } else {
        day = new Date(day3).getDate()
      }
      this.endTimes = `${(new Date(day3)).getFullYear()}-${month}-${day}`
      this.formatDateDaysText = '2天'// 默认时间间隔天数
    },
    // 交换地址
    ExchangeAddress() {
      this.isExchangeAddress = !this.isExchangeAddress
      if (this.isExchangeAddress) { // 单程
        this.goFrom = this.ArrivalText
        this.goArrival = this.fromText
        this.goFromCode = this.goArrivalCode1
        this.goArrivalCode = this.goFromCode1
      } else { // 往返
        this.goFrom = this.fromText
        this.goArrival = this.ArrivalText
        this.goFromCode = this.goFromCode1
        this.goArrivalCode = this.goArrivalCode1
      }
    },
    // 选择地址
    changeAddress(AddressType) {
      this.isAddress = true
      this.AddressType = AddressType
      if (AddressType === 0) { // 出发地
        this.AddressText = '选择出发地'
        this.selAddressCode = this.goFromCode
      } else if (AddressType === 1) { // 目的地
        this.AddressText = '选择目的地'
        this.selAddressCode = this.goArrivalCode
      }
    },
    // 点击显示日历
    isShowCalendar() {
      this.isType === 1 ? this.show = true : this.OneWayShow = true
    },
    // 转化日历组件返回的日期格式
    formatDate(date) {
      return `${date.getMonth() + 1}月${date.getDate()}日`
    },
    formatDates(date) {
      let month, day
      if (date.getMonth() + 1 < 10) {
        month = '0' + (date.getMonth() + 1)
      } else {
        month = date.getMonth() + 1
      }
      if (date.getDate() < 10) {
        day = '0' + date.getDate()
      } else {
        day = date.getDate()
      }
      return `${date.getFullYear()}-${month}-${day}`
    },
    // 计算两个日期之间的天数
    formatDateDays(date) {
      return parseInt(Math.abs(new Date(date[1]).getTime() - new Date(date[0]).getTime()) / 1000 / 60 / 60 / 24) + 1 + '天'
    },
    // 将时间转化为今，明，后,超出转为周几
    transDate(dateText) {
      const tt = new Date(dateText).getTime()// 将参数时间转为毫秒值
      const stamp1 = new Date(new Date().setHours(0, 0, 0, 0)) // 获取当天零点的时间
      const days = parseInt((tt - stamp1) / 86400000)// 取到现在的毫秒值-参数毫秒值 转化为天
      let result
      if (days < 3) {
        if (days === 0) {
          result = '今天'
        } else if (days === 1) {
          result = '明天'
        } else if (days === 2) {
          result = '后天'
        }
      } else {
        const dateList = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
        result = dateList[dateText.getDay()]
      }
      return result
    },
    // 单程日历显示并选择
    onConfirm(date) {
      this.OneWayShow = false
      this.startTime = this.formatDate(date)
      this.startTimes = this.formatDates(date)
      this.startTimeDate = this.transDate(date)
    },
    // 往返日历显示并选择
    onConfirms(date) {
      const [start, end] = date
      this.startTime = this.formatDate(start)
      this.startTimes = this.formatDates(start)
      this.endTime = this.formatDate(end)
      this.endTimes = this.formatDates(end)
      this.show = false
      this.formatDateDaysText = this.formatDateDays(date)
      this.startTimeDate = this.transDate(date[0])
      this.endTimeDate = this.transDate(date[1])
    },
    // 自定义日历文件
    formatter(day) {
      if (day.type === 'start') {
        day.bottomInfo = '去程'
      } else if (day.type === 'end') {
        day.bottomInfo = '返程'
      }
      return day
    },
    // 是否携带儿童
    isChildren() {
      this.isShowChildren = !this.isShowChildren
    },
    // 儿童预定说明显示
    ShowChildrenReservations() {
      this.isShowChildrenNote = true
    },
    // 搜索历史行程
    SearchHistoryVote() {
      this.SearchHistoryList = JSON.parse(localStorage.getItem('SearchHistory')).reverse()
      if (this.SearchHistoryList.length === 0) {
        this.isShowSearchHistory = false
      } else {
        this.isShowSearchHistory = true
      }
    },
    // 清空搜索历史
    ClearHistory() {
      this.SearchHistoryList = ''
      this.isShowSearchHistory = false
      window.localStorage.setItem('SearchHistory', JSON.stringify([]))
    },
    // get城市列表
    getCityLists() {
      getCityList().then(res => {
        if (Number(res.code) === 200) {
          this.hostCity = res.data.hostCity
          this.cityList = res.data.cityList
        }
      })
    },
    // 搜索城市列表
    onSearch(value) {
      if (value === '') {
        this.isSearchList = false
      } else {
        this.isSearchList = true
        searchCityList({
          cityName: value
        }).then(res => {
          if (Number(res.code) === 200) {
            this.SearchCityList = res.data.list
          }
        })
      }
    },
    // 选中搜索城市
    selSearchCity(item, type) {
      this.isAddress = false
      if (this.AddressType === 0) { // 出发地
        if (type === 0) {
          this.goFrom = item.city
          this.goFromCode1 = item.threeCode
          this.goFromCode = this.goFromCode1
          this.fromText = this.goFrom
        } else if (type === 1) {
          this.goFrom = item.city_name
          this.goFromCode1 = item.city_three_code
          this.goFromCode = this.goFromCode1
          this.fromText = this.goFrom
        }
      } else if (this.AddressType === 1) { // 目的地
        if (type === 0) {
          this.goArrival = item.city
          this.goArrivalCode1 = item.threeCode
          this.goArrivalCode = this.goArrivalCode1
          this.ArrivalText = this.goArrival
        } else if (type === 1) {
          this.goArrival = item.city_name
          this.goArrivalCode1 = item.city_three_code
          this.goArrivalCode = this.goArrivalCode1
          this.ArrivalText = this.goArrival
        }
      }
    },
    // 搜索机票
    SearchTicket() {
      let SearchList
      if (this.isType === 0) {
        SearchList = {
          isOneWay: true,
          startText: this.goFrom,
          endText: this.goArrival,
          DepartureTime: this.startTime,
          DepartureTimes: this.startTimes,
          startCode: this.goFromCode,
          endCode: this.goArrivalCode
        }
      } else if (this.isType === 1) {
        SearchList = {
          isOneWay: false,
          startText: this.goFrom,
          endText: this.goArrival,
          startTime: this.startTime,
          endTime: this.endTime,
          startCode: this.goFromCode,
          endCode: this.goArrivalCode
        }
      }
      const sessSearchList = JSON.parse(localStorage.getItem('SearchHistory'))
      if (typeof sessSearchList === 'object') {
        window.localStorage.setItem('SearchHistory', JSON.stringify(
          [SearchList]
        ))
      } else {
        if ((this.SearchHistoryList).length === 2 || (this.SearchHistoryList).length > 2) {
          (this.SearchHistoryList).shift()
          window.localStorage.setItem('SearchHistory', JSON.stringify(
            (this.SearchHistoryList).concat(SearchList)
          ))
        } else {
          window.localStorage.setItem('SearchHistory', JSON.stringify(
            (this.SearchHistoryList).concat(SearchList)
          ))
        }
      }
      this.SearchHistoryVote()// 刷新页面历史记录
      if (this.isType === 0) {
        this.$router.push({
          path: '/tickedList',
          query: {
            isType: this.isType,
            startAddress: this.goFrom, // 出发地
            endAddress: this.goArrival, // 目的地
            startCode: this.goFromCode, // 出发3字码
            endCode: this.goArrivalCode, // 目的3字码
            isChildren: this.isShowChildren, // 是否儿童票
            startTime: this.startTimes// 出发时间
          }
        })
      } else if (this.isType === 1) {
        this.$router.push({
          path: '/RoundTripTickedList',
          query: {
            isType: this.isType,
            startAddress: this.goFrom, // 出发地
            endAddress: this.goArrival, // 目的地
            startCode: this.goFromCode, // 出发3字码
            endCode: this.goArrivalCode, // 目的3字码
            isChildren: this.isShowChildren, // 是否儿童票
            startTime: this.startTimes, // 出发时间
            endTime: this.endTimes// 返程时间
          }
        })
      }
    },
    // 搜索历史查询航班列表
    SearchProcess(item) {
      this.$router.push({
        path: '/tickedList',
        query: {
          isType: this.isType,
          startAddress: item.startText, // 出发地
          endAddress: item.endText, // 目的地
          startCode: item.startCode, // 出发3字码
          endCode: item.endCode, // 目的3字码
          isChildren: this.isShowChildren, // 是否儿童票
          startTime: item.DepartureTimes// 出发时间
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  font-family: 'PingFang SC Regular';
}

.content {
  position: absolute;
  text-align: left;
  background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/17/yuelvhui5ZGSRcbBKi1608888182.png');
  background-position: top left;
  background-size: 100% 224px;
  background-repeat: no-repeat;

  .Return {
    width: 31px;
    height: 31px;
    margin-top: 40px;
    margin-left: 13px;
  }

  .TicketContent {
    width: 92.8%;
    margin: 85px auto;

    .TicketType {
      width: 100%;
      text-align: center;
      line-height: 45px;
      display: flex;

      .TicketOneWay, .TicketReturn {
        background-position: top left;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-size: 15px;
        width: 182px;
      }

      .bgcImage1 {
        height: 45px;
        background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/18/yuelvhui4BsJLQ69yu1608890462.png');
        color: #333333;
        font-weight: 500;
        z-index: 1;
      }

      .bgcImage2 {
        height: 40px;
        margin-top: 5px;
        background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/18/yuelvhuiQydEIwLP6N1608891816.png');
        color: #ffffff;
      }

      .bgcImage3 {
        height: 45px;
        background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/18/yuelvhuiiqPMyvCXHY1608891947.png');
        color: #333333;
        margin-left: -10px;
        font-weight: 500;
      }

      .bgcImage4 {
        height: 40px;
        margin-top: 5px;
        background-image: url('https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-25/18/yuelvhuic5m9PrOEPE1608891683.png');
        color: #ffffff;
        margin-left: -10px;
      }

    }

    .TicketInfo {
      width: 100%;
      background-color: #fff;
      border-radius: 0 0 7px 7px;
      padding: 0px 20px 20px;
      /*往返地区*/

      .VoteAddress {
        display: flex;
        font-size: 22px;
        font-weight: 500;
        color: #333333;
        padding: 20px 0;
        position: relative;

        .RoundTripImg {
          position: absolute;
          left: 50%;
          margin-left: -13.5px;

          img {
            width: 27px;
            height: 27px;
          }
        }

        .ArrivalText {
          margin-left: auto;
        }
      }

      /*往返时间*/

      .IntervalTimeWrap {
        display: flex;
        position: relative;
        padding: 20px 0;

        .DepartureTime, .returnTime {
          color: #333333;
          font-size: 22px;
          font-weight: 500;

          span {
            font-size: 15px;
            font-weight: normal;
            margin-left: 10px;
          }
        }

        .RoundTripInterval {
          position: absolute;
          left: 50%;
          margin-left: -11px;
          top: 50%;
          margin-top: -6px;
          font-size: 14px;
          color: #999999;

        }

        .returnTime {
          margin-left: auto;
        }
      }

      /*携带儿童*/

      .isChildren {
        display: flex;
        padding: 18px 0;

        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          vertical-align: top;
        }

        p {
          font-size: 12px;
          color: #333333;
          font-weight: 500;

          span {
            display: inline-block;
            transform: scale(0.9);
            color: #999999;
            margin-left: 11px;
            font-weight: 300;
          }
        }
      }

      /*儿童预定说明*/

      .ChildrenReservations {
        padding-bottom: 18px;
        display: flex;

        p {
          font-size: 12px;
          color: #666666;
          margin-right: 7px;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }

      /*搜索*/

      .SearchStyle {
        width: 100%;
        height: 45px;
        text-align: center;
        line-height: 45px;
        background-color: #E33F44;
        font-size: 17px;
        color: #ffffff;
        border-radius: 7px;
      }

      /*搜索历史*/

      .SearchHistoryWrap {
        display: flex;
        padding: 23px 0 0 0;

        .SearchHistory {
          width: 77%;
          padding-right: 20px;
          display: flex;
          overflow: scroll;
          white-space: nowrap;
          font-size: 12px;
          color: #666666;

          .SearchContent {
            display: flex;
            margin-right: 20px;

            .TravelTime {
              p {
                margin-left: 5px;
              }

              div {
                display: flex;
              }
            }
          }
        }

        .ClearHistory {
          display: flex;

          img {
            width: 15px;
            height: 16px;
            margin-right: 5px;
          }

          p {
            line-height: 16px;
            font-size: 12px;
            color: #666666;
          }
        }
      }
    }
  }

  /*    儿童预定说明*/

  .ChildrenNoteWrap {
    padding: 25px 17px 62px 17px;

    .ChildrenNote {
      width: 100%;
      border-radius: 7px;
      border: 1px solid #E8E8E8;

      .noteContentWrap {
        border-bottom: 1px solid #e8e8e8;

        .noteContent {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          position: relative;

          div {
            font-size: 13px;
          }

          div:nth-child(1) {
            width: 30%;
            color: #333333;
            font-weight: 600;
            text-align: center;
            position: absolute;
            top: 50%;
            margin-top: -6.5px;
          }

          div:nth-child(2) {
            border-left: 1px solid #E8E8E8;
            margin-left: 91px;
            line-height: 20px;
            color: #666;
            padding: 10px;
          }
        }
      }

      .noteContentWrap:nth-child(4) {
        border-bottom: none;
      }
    }
  }

  /*    选择城市弹窗*/
  /*    搜索框*/

  .SearchBoxWrap {
    padding: 0 17px;

    .SearchBox {
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding-left: 14px;
    }
  }

  .SearchListWrap {
    height: 300px;

    .NoSearchData {
      text-align: center;
      line-height: 50px;
      font-size: 15px;
      color: #666666;
    }

    .SearchData {
      padding: 0 30px;

      div {
        padding: 5px;
      }
    }
  }

  .hostCityStyle {
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    font-weight: 600;
    padding-left: 13px;
  }

  .AddressList {
    display: flex;
    flex-wrap: wrap;
    /*padding:13px;*/

    .AddressListCell {
      width: 75px;
      height: 36px;
      margin-bottom: 10px;
      text-align: center;
      line-height: 36px;
      font-size: 15px;
      color: #333333;
      border: 1px solid #E8E8E8;
      border-radius: 3px;
      margin-left: 13px;
      white-space: nowrap; //规定段落中的文本不进行换行
      /*overflow: hidden;*/
      /*text-overflow:ellipsis;*/
    }

    .AddressListCell2 {
      border: 1px solid #E33F44;
      color: #E33F44;
    }

    .AddressListMargin {
      margin-left: 11px;
    }
  }
}
</style>
